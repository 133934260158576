function calculatePrice(quantity, nrOfTimeslots, promoCode = 'default') {
  /**
   * Example of pricing structure:
   * {
   *   default: {
   *     1: 20
   *     2: 35
   *   },
   *   'thisCodeIsAwesome': {
   *     1: 10
   *   }
   * }
   */
  let { pricing } = JSON.parse(atob(settings));
  if (!pricing.hasOwnProperty(promoCode))
    promoCode = 'default';

  let base = pricing[promoCode];
  let price = base.hasOwnProperty(nrOfTimeslots)
    ? base[nrOfTimeslots]
    : base[1] * nrOfTimeslots;

  return price * quantity;
}
